<template>
  <div></div>
</template>

<script lang="ts">
import { defineComponent } from "vue"

import { useAccountStore } from "@/stores/account"
import { mapStores } from "pinia"

export default defineComponent({
  name: "ReloadIntercom",

  data() {
    return {
      intercomId: import.meta.env.VITE_INTERCOM_ID,
    }
  },

  computed: {
    ...mapStores(useAccountStore),
  },

  watch: {
    $route(to, from) {
      this.handleIntercom(to)
    },
  },

  mounted() {
    this.loadIntercom()
  },

  methods: {
    loadIntercom() {
      let script = document.createElement("script")
      script.setAttribute("src", "/intercom.js")
      script.setAttribute("id", "intercom-script")
      document.head.appendChild(script)

      let container = document.getElementsByClassName("intercom-lightweight-app")[0] as HTMLElement
      if (container) {
        container.style.display = "initial"
      }

      setTimeout(() => {
        const intercomConfig = {
          app_id: this.intercomId,
          alignment: "right",
          horizontal_padding: 30,
          vertical_padding: 30,
          platform: "USKA",
        }

        if (window.intercomSettings === undefined) {
          window.intercomSettings = {}
        }

        Object.assign(window.intercomSettings, intercomConfig)

        window.Intercom("boot", window.intercomSettings)
      }, 1000)
    },

    handleIntercom(to?: any) {
      setTimeout(() => {
        if ((to && to.meta.hideIntercom) || !this.accountStore.hasPremium) {
          window.Intercom("shutdown")
          window.Intercom("hide")
        } else {
          window.Intercom("update", {
            hide_default_launcher: false,
          })
        }
      }, 1000)
    },
  },
})
</script>
